var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-tabel" },
    [
      _vm.dragable
        ? _c(
            "a-table",
            _vm._b(
              {
                ref: _vm.tableRef,
                attrs: { components: _vm.components },
                on: { change: _vm.handleTableChange },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.scopedSlots, function (slot) {
                      return {
                        key: slot,
                        fn: function (text, record, index) {
                          return [
                            _vm._t(slot, null, {
                              scope: {
                                text: text,
                                record: record,
                                index: index,
                              },
                            }),
                          ]
                        },
                      }
                    }),
                    {
                      key: _vm.$scopedSlots.expandedRowRender
                        ? "expandedRowRender"
                        : "",
                      fn: function (record, index, indent, expanded) {
                        return [
                          _vm._t(
                            _vm.$scopedSlots.expandedRowRender
                              ? "expandedRowRender"
                              : "",
                            null,
                            null,
                            {
                              record: record,
                              index: index,
                              indent: indent,
                              expanded: expanded,
                            }
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              "a-table",
              Object.assign({}, _vm.$props),
              false
            ),
            [
              _vm._l(_vm.slots, function (slot) {
                return _c("template", { slot: slot }, [_vm._t(slot)], 2)
              }),
            ],
            2
          )
        : _c(
            "a-table",
            _vm._b(
              {
                ref: _vm.tableRef,
                attrs: { "data-source": _vm.subDataSource },
                on: { change: _vm.handleTableChange },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.scopedSlots, function (slot) {
                      return {
                        key: slot,
                        fn: function (text, record, index) {
                          return [
                            slot === "operation"
                              ? [
                                  _vm._t(slot, null, {
                                    scope: _vm.getOperationVal(text, index),
                                  }),
                                ]
                              : [
                                  _vm._t(slot, null, {
                                    scope: {
                                      text: text,
                                      record: record,
                                      index: index,
                                    },
                                  }),
                                ],
                          ]
                        },
                      }
                    }),
                    {
                      key: _vm.$scopedSlots.expandedRowRender
                        ? "expandedRowRender"
                        : "",
                      fn: function (record, index, indent, expanded) {
                        return [
                          _vm._t(
                            _vm.$scopedSlots.expandedRowRender
                              ? "expandedRowRender"
                              : "",
                            null,
                            null,
                            {
                              record: record,
                              index: index,
                              indent: indent,
                              expanded: expanded,
                            }
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              "a-table",
              Object.assign({}, _vm.$props),
              false
            ),
            [
              _vm._l(_vm.slots, function (slot) {
                return _c("template", { slot: slot }, [_vm._t(slot)], 2)
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }